.carousel img {
    height: 20%;
}

/* #mainschools .card {
    border-radius: 10px;
} */

#mainschools a {
    text-decoration: none;
}

#states .highlight {
    color: black;
}

#states .highlight:hover {
    color: #777575f0;
    text-decoration: none;
}

img {
    transition: all .3s ease-out 0s;
}

img.banner {
    position: relative;
}

img.circle {
    -webkit-animation: circlee 6s linear 0s infinite alternate;
    animation: circlee 6s linear 1s infinite alternate;
    position: absolute;
    top: 18%;
}

@keyframes circlee {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

img.hero3 {
    -webkit-animation: hero3 6s linear 0s infinite alternate;
    animation: hero3 6s linear 1s infinite alternate;
    margin-top: 29%;
    left: 20%;
    position: absolute;
}

@keyframes hero3 {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-100px);
    }
}

#mainAcad a {
    color: black;
}

#mainAcad a:hover {
    text-decoration: none;
}

.box {
    transition: box-shadow .5s;
}

.box:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, .5);
}

.category__item {
    padding: 20px 20px;
    border-radius: 6px;
    border: 2px solid #eef0f6;
    transition: box-shadow .5s;
    background-color: white;
}

.category__item:hover {
    background-color: #2b4eff;
    color: white;
    box-shadow: 0 0 11px rgba(33, 33, 33, .5);
}

.category__title {
    font-size: 20px;
    line-height: 1;
    margin-bottom: 0;
}

.category__content p {
    font-weight: 500;
    margin-bottom: 0;
}

img.hero {
    right: 0;
    bottom: 38%;
    float: right;
    position: absolute;
}

img.hero2 {
    bottom: -29%;
    left: 0;
    position: absolute;
}

#bannerDiv {
    width: 100%;
}

@media screen and (min-width: 786px) {
    #bannerDiv {
        width: 60%;
    }

    img.hero2 {
        bottom: 29%;
        left: 0;
        position: absolute;
    }

    img.circle {
        -webkit-animation: circlee 6s linear 0s infinite alternate;
        animation: circlee 6s linear 1s infinite alternate;
        position: absolute;
        top: -10%;
    }
}